import * as React from 'react';
import { FourOhFour } from '../components/index';

const FourOhFourPage = () => (
    <main>
        <title>nickyb.cool</title>
        <FourOhFour />
    </main>
);

export default FourOhFourPage;
